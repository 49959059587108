.LinksToSearch-module__container {
  display: flex;
  overflow-y: auto;
  margin-top: 17px;
  padding: 0 20px;
  box-sizing: border-box;
}

.LinksToSearch-module__container > a {
  color: #484848;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  border-radius: 6px;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 12px;
}

body[dir="rtl"] .LinksToSearch-module__container > a + a {
  margin-right: 8px;

}

body[dir="rtl"] .LinksToSearch-module__container > a:last-of-type{
  margin-left: 20px;
}

body[dir="ltr"] .LinksToSearch-module__container > a:last-of-type{
  margin-right: 20px;
}

body[dir="ltr"] .LinksToSearch-module__container > a + a {
  margin-left: 8px;
}

.LinksToSearch-module__moreLink {
  border: solid 1px #154f9c;

  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  max-height: 40px;
  padding: 0;
  min-width: 100px;
}

.LinksToSearch-module__moreLink > span {
  color: #154f9c !important;
  font-weight: normal;
}

/* body[dir="rtl"] .moreLink {
  margin-left: 10px;
} */


body[dir="ltr"] .LinksToSearch-module__moreLink img {
  transform: rotateY(180deg);
}